<template>
    <div class="wrapper-ficha noticia">

        <a @click="back" class="volver"><img src="/img/flecha_salir-azul.svg" alt=""></a>


        <div class="contenedor-evento" v-if="data">

            <div class="imagen-principal">
                <img :src="data.image.url" :alt="data.title">
            </div>


            <div class="contenedor-cuerpo">

                <div class="cuerpo-real">
                    <h2>{{data.title}}</h2>
                    <p>{{data.category.name}}</p>
                    <h4 class="js-fecha">{{data.date | date}}</h4>

                    <p><strong>Organizador:</strong> {{data.organizer}}</p>
                    <p><strong>Precio:</strong> {{data.price}}</p>
                    <p><strong>Horario:</strong> {{data.schedule}}</p>
                    <p><strong>Dirección:</strong> {{data.address}}</p>

                    <editorjs :modulos="data.description"></editorjs>
                    <div class="contenedor-boton row-start" v-if="logged">
                        <button @click="openPopup" class="boton-azul -popup" v-if="data.open == 1 && data.status == 0 && !fechaactual && data.type == 0">Inscribirse</button>
                        <template v-else>
                            <a v-if="data.open == 1 && data.status == 0 && !fechaactual" @click="inscripcion" class="boton-azul" :loading="loading">Inscribirse</a>
                        </template>
                    </div>

                    <div class="contenedor-boton row-start" v-if="logged">
                        <a v-if="data.open == 1 && data.status == 1 && !fechapasada" @click="cancelar" class="boton-gris" :loading="loading">Cancelar inscripción</a>
                        <p v-if="data.status == 2"><strong>Inscripción cancelada</strong></p>
                    </div>

                    <div class="contenedor-boton row-start" v-else>
                        <router-link v-if="data.open == 1 && !fechaactual" :to="`/login?callback=${$route.fullPath}`" class="boton-azul">Inscribirse</router-link>
                    </div>

                </div>

            </div>
        </div>

        <section class="popup-mask" v-if="popup">
            <div class="container-mask">
                <a class="close" @click="closePopup">✕</a>
                <div class="contenedor-input">
                    <p class="title">Por favor selecciona el tipo de asistencia para inscribirte a este evento:</p>
                    <span>
                        <div class="contenedor-input row-start fww">
                            <input type="radio" value="1" name="presencialidad" id="presencialidad" v-model="data.type">
                            <label for="presencialidad">Presencial</label>
                            <input type="radio" value="2" name="online" id="online" v-model="data.type">
                            <label for="online">Online</label>
                        </div>
                    </span>
                </div>
                <a v-if="data.open == 1 && data.status == 0 && !fechaactual" @click="inscripcion" class="boton-azul" :loading="loading">Inscribirse</a>
            </div>
        </section>

    </div>
</template>


<script>

    import { mapActions, mapGetters } from 'vuex';
    import router from '@/router';

    export default {
        name: 'evento',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            data_type: null,
            loading: false,
            popup: false
        }),
        methods: {
            ...mapActions(['requestEvento', 'requestInscripcion', 'requestCancelInscripcion']),
            setData() {
                this.requestEvento(this.$route.params.id).then(r => {
                    this.data = r;
                })
            },
            openPopup() {
                this.popup = true
            },
            closePopup() {
                this.popup = false
            },
            back() {
                router.go(-1)
            },
            inscripcion() {
                this.loading = true;
                this.requestInscripcion(this.data).then(() => {
                    this.loading = false;
                    this.setData();
                    this.popup = false
                });
            },
            cancelar() {
                this.loading = true;
                this.requestCancelInscripcion(this.data.id).then(() => {
                    this.loading = false;
                    this.setData();
                });
            }
        },
        computed: {
            ...mapGetters({
                logged: 'getLogged'
            }),
            fechapasada() {

                if (this.data.limit_cancel_date) {

                    let pasada = new Date(this.data.limit_cancel_date).getTime();
                    let actual = new Date().getTime();

                    return (actual > pasada)
                } else {
                    return false;
                }
            },
            fechaactual() {
                let pasada = new Date(this.data.date).getTime();
                let actual = new Date().getTime();

                return (actual > pasada)
            }
        },
        watch: {
            '$route'() {
                this.setData();
            }
        }
    }
</script>